import React from "react";
import Link from "next/link";

export default React.memo(function Hero() {
  return (
    <>
      <section className="d-none d-md-block">
        <div
          className="w-100 bg-cover"
          style={{ backgroundImage: `url("/img/branding/home/hero-web.png")` }}
        >
          <div className="container d-flex flex-column">
            <div
              className="row align-items-center py-12"
              style={{ minHeight: 550 + "px" }}
            >
              <div className="col-12 col-md-6 col-lg-5 col-xl-4 offset-md-1">
                <h1 className="display-4">A Box Full of Makeup...</h1>

                <h2 className="display-4 text-primary">And That's it!</h2>

                <p className="mb-8 font-size-lg">*Starting at $34.99</p>
                <Link href="/quiz">
                  <a className="btn btn-dark">
                    Get Started <i className="fe fe-arrow-right ml-2" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="d-md-none">
        <div className="w-100">
          <img className="img-fluid" src="/img/branding/home/hero-mobile.png" />
        </div>

        <div style={{ marginTop: "-1rem" }}>
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <div className="bg-light w-50" style={{ minHeight: "1rem" }} />
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center bg-light text-center p-5">
            <h1 className="display-4">A Box Full of Makeup...</h1>

            <h2 className="display-4 text-primary">And That's it!</h2>

            <p className="mb-8 font-size-lg">*Starting at $34.99</p>
            <Link href="/quiz">
              <a className="btn btn-dark">
                Get Started <i className="fe fe-arrow-right ml-2" />
              </a>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
});
