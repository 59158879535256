import React from "react";
import { useCart } from "../services/hooks/useCart";
import { useAuth } from "../services/hooks/useAuth";
import { useRedirect } from "../services/hooks/useRedirect";
import currency from "currency.js";

export default React.memo(function SubscriptionPlan({ product }) {
  const { addToCart, isWaitlisting } = useCart();

  const { user } = useAuth();

  const { redirect } = useRedirect();

  const { id, price, name, metadata } = product;

  const addSubscriptionToCart = (e) => {
    e.preventDefault();

    addToCart(
      {
        product,
        quantity: 1,
      },
      { silent: true }
    );

    if (user && user.preferenceId) {
      return redirect("/checkout");
    }

    redirect("/quiz");
  };

  return (
    <div className="col-12 col-lg-4">
      <div className="card card-lg border-2 shadow mb-7 mb-lg-0">
        <div className="card-body border-bottom text-center">
          <h5>{name}</h5>

          <h2 className="mb-0 font-weight-bolder">
            {currency(price).format()}
          </h2>
        </div>
        <div className="card-body">
          <p className="text-muted">{metadata?.billingText}</p>
          <ul className="list-styled mb-8">
            {metadata?.features.map((feature, index) => (
              <li key={index} className="list-styled-item">
                <i className="fe fe-check mr-2 text-primary" /> {feature}
              </li>
            ))}
          </ul>

          <div className="text-center">
            <button
              className="btn btn-outline-dark"
              onClick={addSubscriptionToCart}
            >
              {isWaitlisting ? "Join Waitlist" : "Get Your Box"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
