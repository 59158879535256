import React, { useEffect } from "react";
import SubscriptionPlan from "./SubscriptionPlan";
import Loader from "./Loader";
import Notice from "./Notice";
import { useSubscriptionPlans } from "../services/hooks/useSubscriptionPlans";

export default React.memo(function Subscriptions() {
  const {
    fetchSubscriptionPlans,
    subscriptionPlans,
    subscriptionPlansError,
    subscriptionPlansStatus,
  } = useSubscriptionPlans();

  useEffect(() => {
    if (!subscriptionPlans?.length) {
      fetchSubscriptionPlans();
    }
  }, []);

  return (
    <section className="py-6 bg-white" id="subscribe">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="mb-5">
              Become a{" "}
              <a
                href="#"
                className="styled-underlined-link"
                data-toggle="tooltip"
                title=""
              >
                #chicbabe
              </a>
              :
            </h2>

            <h6 className="mb-3">
              You get to choose 5 - 6 full-size, brand name beauty products.
            </h6>
            <h6 className="mb-3">
              Select a bi-monthly, 6 or 12 months option below.
            </h6>
          </div>
        </div>
        <div className="row">
          {subscriptionPlansStatus !== "ready" ? (
            <Loader />
          ) : subscriptionPlansError ? (
            <div className="col">
              <Notice message={subscriptionPlansError} type="danger" />
            </div>
          ) : (
            subscriptionPlans.map((subscriptionPlan) => (
              <SubscriptionPlan
                key={subscriptionPlan.id}
                product={subscriptionPlan}
              />
            ))
          )}
        </div>

        <p className="text-center mt-2">
          All plans <strong>automatically renews</strong> and you are{" "}
          <strong>*committing to the length of your plan</strong>.
        </p>
      </div>
    </section>
  );
});
