import React from "react";

import Hero from "./Hero";
import Subscriptions from "./Subscriptions";
import Footer from "./Footer";
import HowItWorks from "./HowItWorks";
import GetStarted from "./GetStarted";

export default function Home() {
  return (
    <>
      <Hero />
      <Subscriptions />
      <HowItWorks />
      <GetStarted />
      <Footer />
    </>
  );
}
