import React from "react";

export default React.memo(function GetStarted() {
  return (
    <section className="pt-12 pb-6" id="getStarted">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="mb-8">Ready to get Started?</h2>

            <a href="#subscribe" className="btn btn-dark mb-10">
              Get Your Box
            </a>
          </div>
        </div>
      </div>
    </section>
  );
});
