import { useRef } from "react";
import { useGlobal } from "../../services/hooks/useGlobal";
import closeModals from "../../util/closeModals";
import scrollToTop from "../../util/scrollToTop";

export const usePageTransition = (
  scrollTop = false,
  hideModals = true,
  deregisterPendingRequests = true
) => {
  const { setPageTransition, clearApiRequests } = useGlobal();
  const transitionTimeout = useRef(null);

  const startTransition = () => {
    clearTimeout(transitionTimeout.current);

    setPageTransition({
      isPageTransitioning: true,
    });

    if (scrollTop) {
      scrollToTop();
    }

    if (hideModals) {
      closeModals();
    }

    if (deregisterPendingRequests) {
      clearApiRequests();
    }
  };

  const completeTransition = () => {
    const defaultDuration = 500;

    transitionTimeout.current = setTimeout(() => {
      setPageTransition({
        isPageTransitioning: false,
      });
    }, defaultDuration);
  };

  return { startTransition, completeTransition };
};
