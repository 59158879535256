import { useState, useEffect } from "react";
import { useApiCall } from "./useApiCall";

export const useSubscriptionPlans = (autoFetch = false) => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [subscriptionPlansError, setSubscriptionPlansError] = useState();
  const [subscriptionPlansStatus, setSubscriptionPlansStatus] =
    useState("idle");

  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();

  const fetchSubscriptionPlans = () => {
    const requestOptions = {
      url: `/subscriptions/plans`,
    };

    executeApiCall(requestOptions);

    setSubscriptionPlansStatus("pending");
  };

  useEffect(() => {
    if (autoFetch) {
      fetchSubscriptionPlans();
    }
  }, [autoFetch]);

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse?.data?.data) {
          const { plans } = apiCallResponse.data.data;

          setSubscriptionPlans(plans);
          setSubscriptionPlansStatus("ready");
        } else {
          setSubscriptionPlansError(apiCallResponse.data.error.userMessage);
          setSubscriptionPlansStatus("ready");
        }
        break;
      case "error":
        setSubscriptionPlansStatus("ready");
        break;
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    subscriptionPlans,
    subscriptionPlansError,
    subscriptionPlansStatus,
    fetchSubscriptionPlans,
  };
};
