import React, { useEffect } from "react";
import Home from "../components/Home";

import { usePageTransition } from "../services/hooks/usePageTransition";

export default function index() {
  const { startTransition, completeTransition } = usePageTransition();

  useEffect(() => {
    completeTransition();

    return () => {
      startTransition();
    };
  }, []);

  return <Home />;
}
