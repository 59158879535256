import React from "react";

export default React.memo(function HowItWorks() {
  return (
    <section className="border-bottom" id="how-it-works">
      <h2 className="mt-5 text-center">Here's how it works:</h2>
      <section className="container mb-5">
        <div className="d-flex flex-column flex-md-row justify-content-around align-items-center">
          <div className="w-50 w-md-25">
            <img
              src="/img/branding/home/home-1.png"
              className="img-fluid"
              style={{ transform: "rotate(7deg)" }}
            />
          </div>

          <div className="text-md-left text-center">
            <h2>Fill Out Your Profile</h2>
            <p>
              Tell us about your beauty preferences and we’ll use them to
              personalize your box.
            </p>
          </div>
        </div>
      </section>

      <section className="container mb-5">
        <div className="d-flex flex-column flex-md-row justify-content-around align-items-center">
          <div className="text-md-left text-center pr-md-10">
            <h2>Explore Your Box</h2>
            <p>
              Your monthly beauty treats will be a mix of prestige and niche
              brands. Discover new favorites and see what works for you.
            </p>
          </div>

          <div className="w-75 w-md-50">
            <img src="/img/branding/home/home-2.jpg" className="img-fluid" />
          </div>
        </div>
      </section>

      <section className="container mb-5">
        <div className="d-flex flex-column flex-md-row justify-content-around align-items-center">
          <div className="w-md-50">
            <img src="/img/branding/home/home-3.png" className="img-fluid" />
          </div>

          <div className="text-md-left text-center">
            <h2>Buy What You Love</h2>
            <p>
              Purchase full-size versions of your faves. Get $1 back in Loyalty
              Points for every $10 you spend in our stores.
            </p>
          </div>
        </div>
      </section>

      <section className="container mb-5">
        <div className="d-flex flex-column flex-md-row justify-content-around align-items-center">
          <div className="text-md-left text-center pr-md-10">
            <h2>Customize Your Box</h2>
            <p>
              You can choose 3 - 4 of your products in each box. Plus, review
              products so we learn more about your experience while you earn
              Loyalty Points.
            </p>
          </div>

          <div className="">
            <img src="/img/branding/home/home-4.png" className="img-fluid" />
          </div>
        </div>
      </section>
    </section>
  );
});
